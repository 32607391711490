import React, { useState, useRef, useEffect } from "react";
import Icon from "@onedesign/icon";
import { Box, FullButton, Select } from "@oneboard/ui-components";
import { useDeviceDetectDispatch } from "../../providers/DeviceDetectProvider";
import { Input } from "../../components/Input";
import { StyledIntro } from "./Intro.style";
import { t } from "utils/i18n";

// const deviceTypes = [
//   "請選擇使用裝置",
//   "筆記型電腦",
//   "桌上型電腦",
//   "dcm",
//   "平板",
//   "手機",
//   "不清楚",
// ];

// const boardItems = ["請選擇品牌", "Apple", "三星", "華為", "小米", "其他"];

// const osItems = ["請選擇系統", "ios系統", "安卓系統"];
const deviceTypes = [
  "Vui lòng chọn thiết bị để sử dụng",
  "Máy tính xách tay",
  "Máy tính để bàn",
  "MacBook (máy tính xách tay, máy tính để bàn)",
  "Máy tính bảng",
  "Điện thoại di động",
  "Không rõ ràng",
];

const boardItems = [
  "Vui lòng chọn một thương hiệu",
  "Apple",
  "Samsung",
  "Huawei",
  "Xiaomi",
  "Khác",
];

const osItems = ["Vui lòng chọn hệ thống", "Hệ thống IOS", "Hệ thống Android"];

export const Intro = ({ className }) => {
  const trans = (key, params) => {
    return t(`packages.deviceDetect.containers.intro.${key}`, "", params);
  };

  const { startDetect, updateDevice } = useDeviceDetectDispatch();

  const [deviceVal, setDeviceVal] = useState(null);
  const [tableVal, setTableVal] = useState(null);
  const [mobileVal, setMobileVal] = useState(null);
  const otherVal = useRef(null);

  const detectItems = [
    {
      icon: "WifiSolid",
      name: trans("__network"),
    },
    {
      icon: "VideoSolid",
      name: trans("__video"),
    },
    {
      icon: "MicrophoneAltSolid",
      name: trans("__microphone"),
    },
    {
      icon: "HeadsetSolid",
      name: trans("__headphonesSpeakers"),
    },
  ];

  const otherInputHandler = (e) => (otherVal.current = e.target.value);

  const startDetectHandler = () => {
    const data = {};

    if (deviceVal === "Điện thoại di động") data.mobileSystem = mobileVal;

    if (deviceVal === "Máy tính bảng") data.tableBoard = tableVal;

    if (deviceVal === "Máy tính bảng" && tableVal === "Khác")
      data.otherBoard = otherVal.current;

    if (deviceVal !== null) {
      data.type = deviceVal;
      updateDevice(data);
    }

    startDetect();
  };

  useEffect(() => {
    if (deviceVal === null) return;
    if (deviceVal !== "Máy tính bảng") {
      otherVal.current = "";
      setTableVal("");
    }
  }, [deviceVal]);

  useEffect(() => {
    if (tableVal === null) return;
    if (tableVal !== "Khác") otherVal.current = "";
  }, [tableVal]);

  return (
    <StyledIntro className={className} data-testid="Intro">
      <div className="desc">
        {trans("__deviceCheckInstructions")}
        <br />
        {trans("__ifYourDeviceReady")}
      </div>
      <div className="detectBlock">
        {detectItems.map((obj) => (
          <div className="detectItems" key={obj.name}>
            <div className="item">
              <div className="icon">
                <Icon name={obj.icon} size="xl" color="#8A94A6" />
              </div>
              <div className="name">{obj.name}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="content">
        <Select
          className="select"
          defaultValue="Vui lòng chọn thiết bị để sử dụng"
          onChange={setDeviceVal}
        >
          {deviceTypes.map((item) => {
            return (
              <Select.Option
                value={item}
                disabled={item === "Vui lòng chọn thiết bị để sử dụng"}
                key={item}
              >
                {item}
              </Select.Option>
            );
          })}
        </Select>
        {deviceVal === "Máy tính bảng" && (
          <Box mt={3}>
            <Select
              className="select"
              defaultValue="Vui lòng chọn một thương hiệu"
              onChange={setTableVal}
            >
              {boardItems.map((item) => {
                return (
                  <Select.Option
                    value={item}
                    disabled={item === "Vui lòng chọn một thương hiệu"}
                    key={item}
                  >
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Box>
        )}
        {deviceVal === "Điện thoại di động" && (
          <Box mt={3}>
            <Select
              className="select"
              defaultValue="Vui lòng chọn hệ thống"
              onChange={setMobileVal}
            >
              {osItems.map((item) => {
         
                return (
                  <Select.Option
                    value={item}
                    disabled={item === "Vui lòng chọn hệ thống"}
                    key={item}
                  >
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Box>
        )}
        {deviceVal === "Máy tính bảng" && tableVal === "Khác" && (
          <Box mt={3}>
            <Input onChange={otherInputHandler} />
          </Box>
        )}
        <Box mt={4}>
          <div className="action">
            <FullButton onClick={startDetectHandler}>
              {trans("__startCheck")}
            </FullButton>
          </div>
        </Box>
      </div>
    </StyledIntro>
  );
};
