import React, { useState, useRef, useCallback, useEffect } from "react";

import { useDeviceDetectDispatch } from "../../providers/DeviceDetectProvider";
import { Box, FullButton, Select } from "@oneboard/ui-components";
import { SpeedTest } from "../../components/SpeedTest";
import { Input } from "../../components/Input";
import { StyledNetworkStep } from "./NetworkStep.style";
import { t } from "utils/i18n";
// const networkType = [
//   '請選擇連線方式',
//   '有線',
//   'WiFi',
//   '手機連線',
//   '不清楚'
// ];

// const networkSource = [
//   '請選擇網路來源',
//   '自宅專用固定網路',
//   '社區&租屋分享網路',
//   '電視網路(第四台網路)',
//   '學校網路',
//   '手機&行動裝置網路',
//   '其他',
//   '不清楚'
// ];
const networkType = [
  "Vui lòng chọn phương thức kết nối",
  "Mạng dây",
  "WiFi",
  "Kết nối di động",
  "Không chắc",
];
const networkSource = [
  "Vui lòng chọn nguồn mạng",
  "Mạng cố định dùng tại nhà",
  "Mạng chia sẻ cộng đồng và cho thuê",
  "Mạng lưới truyền hình",
  "Mạng lưới trường học",
  "Di động & Mạng di động",
  "Khác",
  "Không chắc",
];

export const NetworkStep = ({ className }) => {
  const trans = (key, params) => {
    return t(`packages.deviceDetect.containers.networkStep.${key}`, "", params);
  };
  const { goVideo, updateNetwork } = useDeviceDetectDispatch();
  const networkData = useRef(null);
  const typeVal = useRef(null);
  const otherVal = useRef(null);

  const [sourceVal, setSourceVal] = useState(null);
  const [speedTestState, setSpeedTestState] = useState(false);

  const updateNetworkData = (data) => (networkData.current = data);

  const goNextHandler = useCallback(() => {
    if (networkData === null) return;

    const { ip, speedDown, speedUp, speedJitter, speedPing } =
      networkData.current;
    let data = {
      ip,
      speedDown,
      speedUp,
      speedJitter,
      speedPing,
    };

    if (typeVal !== null) {
      data = {
        ...data,
        type: typeVal.current,
      };
    }

    if (sourceVal !== null) {
      data = {
        ...data,
        source: sourceVal,
      };
    }

    if (sourceVal === "Khác") {
      data = {
        ...data,
        other: otherVal.current,
      };
    }

    updateNetwork(data);
    goVideo();
  }, [sourceVal]);

  const otherInputHandler = (e) => (otherVal.current = e.target.value);

  const nextBtnHandler = () => setSpeedTestState(true);

  useEffect(() => {
    if (sourceVal === null) return;
    if (sourceVal !== "Khác") {
      otherVal.current = null;
    }
  }, [sourceVal]);


  return (
    <StyledNetworkStep className={className} data-testid="NetworkStep">
      <div className="content">
        <div className="itemWrap iframe">
          <SpeedTest
            nextHandler={nextBtnHandler}
            updateHandler={updateNetworkData}
          />
        </div>
        <div className="itemWrap form">
          <Box>
            <Select
              className="select"
              defaultValue="Vui lòng chọn phương thức kết nối"
              onChange={(val) => (typeVal.current = val)}
            >
              {networkType.map((item) => {
                return (
                  <Select.Option
                    value={item}
                    disabled={item === "Vui lòng chọn phương thức kết nối"}
                    key={item}
                  >
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Box>
          <Box mt={4}>
            <Select
              className="select"
              defaultValue="Vui lòng chọn nguồn mạng"
              onChange={setSourceVal}
            >
              {networkSource.map((item) => {
                return (
                  <Select.Option
                    value={item}
                    disabled={item === "Vui lòng chọn nguồn mạng"}
                    key={item}
                  >
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Box>
          {sourceVal === "Khác" && (
            <Box mt={4}>
              <Input onChange={otherInputHandler} />
            </Box>
          )}
          <Box mt={4}>
            <div className="notice">
              {trans("__clickStartNetworkTest")}
              <br />
              {trans("__afterTestClickNext")}
            </div>
          </Box>
        </div>
      </div>
      <Box mt={4}>
        <div className="action">
          <FullButton onClick={goNextHandler} disabled={!speedTestState}>
            {trans("__next")}
          </FullButton>
        </div>
      </Box>
    </StyledNetworkStep>
  );
};
