import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { StyledSpeedTest } from './SpeedTest.style';

const init = ({nextFunc, updateFunc, updateLoadFunc}) => {
  window.addEventListener('message', function(e) {
    const isTestEnd = e.data.testData;
    
    if (isTestEnd) {
      const testData = e.data.testData;
      const speedDown = testData.dlStatus;
      const speedUp = testData.ulStatus;
      const speedPing = testData.pingStatus;
      const speedJitter = testData.jitterStatus;
      const ip = testData.clientIp;
      const data = {
        speedDown,
        speedUp,
        speedPing,
        speedJitter,
        ip
      };

      updateFunc(data);
      nextFunc();
    }
	});
};

export const SpeedTest = ({ className, nextHandler, updateHandler }) => {
  const [isLoading, setIsLoading] = useState(true);
  
  const updateLoadingHandler = (state) => setIsLoading(state);

  const onLoadHandler = () => init({ nextFunc: nextHandler, updateFunc: updateHandler, updateLoadFunc: updateLoadingHandler });

  return (
    <StyledSpeedTest className={className} data-testid="SpeedTest">
      <iframe
        className="iframe"
        id="speedtest"
        frameBorder="0"
        title="speedtestcustom"
        src='https://spt.oneclass.com.tw/speedtest-vn.html'
        onLoad={onLoadHandler}
        >
      </iframe>
      {/* {
      isLoading && (
        <div className="loadingBox">
          <LoadingOutlined style={{ color: '#A1A4B1', fontSize: '32px' }} />
        </div>
      )} */}
    </StyledSpeedTest>
  );
};